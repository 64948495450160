import {navigate} from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../AppContext';
import imgIconFb from '../images/icon-menu-facebook.png';
import imgIconIg from '../images/icon-menu-instagram.png';
import imgIconLine from '../images/icon-menu-line.png';
import imgLineBadge from '../images/icon-line-badge.png';
import URL_PATH from '../UrlPath';
import {BREAK_POINTS, LINE_FRIEND_LINK} from '../domain/Constants';

const COLUMN_1 = [
  {
    label: '首頁',
    link: URL_PATH.landing,
  },
  {
    label: '關於法朋',
    link: URL_PATH.about,
  },
  {
    label: '最新消息',
    link: URL_PATH.newsList,
  },
  {
    label: '商品列表',
    link: URL_PATH.productList,
  },
];
const COLUMN_2 = [
  {
    label: '喜餅與彌月',
    link: URL_PATH.foretaste,
  },
  {
    label: '常見問題',
    link: URL_PATH.faq,
  },
  {
    label: '會員中心',
    link: URL_PATH.profile,
  },
];

const COLUMN_3 = [
  {
    label: '客服服務時間 週一至週日 10:00-17:00',
    link: null,
  },
  {
    label: '客服服務電話 02-8978 8332',
    link: null,
  },
];

const COLUMN_4 = [
  {
    label: '營業時間 週一至週日 11:30-19:30',
    link: null,
  },
  {
    label: '最後取貨時間 週一至週日 19:00',
    link: null,
  },
  {
    label: '客服服務時間 10:00-17:00',
    link: null,
  },
  {
    label: '聯絡電話 02 8978 8332',
    link: null,
  },
  {
    label: '門市地址 10693台北市大安區仁愛路四段300巷20弄11號',
    link: null,
  },
  {
    label: '法朋有限公司 53930285',
    link: null,
  },
];

function Footer(props) {
  return (
    <Wrapper>
      <div className="container">
        <div className="left">
          <div className="brand-column">
            <h3 className="brand">Le Ruban Pâtisserie</h3>
            <div className="social-medias">
              <a
                className="social-icon-btn"
                href={LINE_FRIEND_LINK}
                target="_blank">
                <img src={imgIconLine} />
              </a>

              <a
                className="social-icon-btn"
                href={'https://www.instagram.com/lerubanpatisserie/?hl=zh-tw'}
                target="_blank">
                <img src={imgIconIg} />
              </a>
              <a
                className="social-icon-btn"
                href={'https://www.facebook.com/LeRubanPatisserie'}
                target="_blank">
                <img src={imgIconFb} />
              </a>
            </div>
          </div>
        </div>

        <div className="right">
          <div className="column">
            {COLUMN_1.map((route, idx) => (
              <a
                key={idx}
                className="column-item link"
                href={''}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(route.link);
                }}>
                {route.label}
              </a>
            ))}
          </div>

          <div className="column">
            {COLUMN_2.map((route, idx) => (
              <a
                key={idx}
                className="column-item link"
                href={''}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(route.link);
                }}>
                {route.label}
              </a>
            ))}
          </div>

          <div className="column" style={{flexBasis: 280}}>
            {COLUMN_3.map((route, idx) => (
              <p key={idx} className="column-item">
                {route.label}
              </p>
            ))}
          </div>

          <div className="column" style={{flexBasis: 280}}>
            {COLUMN_4.map((route, idx) => (
              <p key={idx} className="column-item">
                {route.label}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="copyright">
        © 2021 Le Ruban Pâtisserie all rights reserved.
      </div>
      <div className="badge">
        <img
          src={imgLineBadge}
          onClick={() => window.open(LINE_FRIEND_LINK, '_blank')}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;
  background-color: #fff;

  & > .badge {
    position: fixed;
    right: calc(
      20px + ${(props) => (props.isMenuOpen ? 'var(--menu-width)' : '0px')}
    );
    transition: right 0.08s ease-in;
    bottom: 20px;
    z-index: 10;

    & > img {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
  }

  & > .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 70px var(--navbar-padding) 80px;

    & > .right {
      flex: 1;
      justify-content: flex-end;
      & > .column:last-child {
        margin-right: 0px;
      }
    }
    & > .left {
      margin-right: 60px;
    }

    & > .left,
    & > .right {
      display: flex;
      margin-top: 20px;

      & > .column {
        display: flex;
        flex-direction: column;
        flex-basis: 120px;
        margin-right: 20px;

        & > .column-item {
          font-size: 16px;
          color: #8e8e93;
          text-align: left;
          margin-bottom: 14px;
          white-space: pre-wrap;
        }

        & > .column-item.link {
          cursor: pointer;
        }
      }

      & > .brand-column {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        & > .brand {
          flex: 1;
          font-size: 24px;
          color: #595757;
          letter-spacing: 0;
          text-align: left;
          margin-bottom: 20px;
        }

        & > .social-medias {
          display: flex;

          & > .social-icon-btn {
            cursor: pointer;
            border: 1px solid #e5e5ea;
            border-radius: 50%;
            overflow: hidden;
            width: 40px;
            height: 40px;
            margin-right: 15px;

            & > img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  & .copyright {
    background-color: var(--theme-primary);
    padding: 18px 10px;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-family: 'Noto Sans TC';
  }

  @media screen and (max-width: ${BREAK_POINTS.tablet}px) {
    & > .container {
      flex-direction: column;
      & > .left {
        margin-right: 0px;
      }
      & > .right {
        flex-wrap: wrap;
        justify-content: flex-start;
        & > .column {
          flex-basis: auto;
        }
      }
    }
  }
`;

export default Footer;
